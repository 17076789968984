@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Urbanist";
  scroll-behavior: smooth;
}

/* badges styles */
.success-badge {
  background: #dbffdf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2b8c34;
  padding: 4px 12px;
  gap: 2px;
  width: fit-content;
}


.pending-badge {
  background: #fff3ce;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d5a407;
  padding: 4px 12px;
  gap: 2px;
  width: fit-content;
}

.failed-badge {
  background: #fdded4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b92043;
  padding: 4px 12px;
  gap: 2px;
  width: fit-content;
}

.scheduled-badge {
  background: #c9f7fc;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0066af;
  padding: 4px 12px;
  gap: 2px;
  width: fit-content;
}

.processing-badge {
  background: #e86454;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  padding: 4px 12px;
  gap: 2px;
  width: fit-content;
}

/* radio buttons */
input[type="radio"] {
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;

  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;

  /* The background will be the radio dot's color. */
  background: #ffffff;

  /* The border will be the spacing between the dot and the outer circle */
  border: 1px solid #96a397;

  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #96a397;
}

input[type="radio"]:checked {
  box-shadow: 0 0 0 1px #2b8c34;
  background-color: #2b8c34;
  border: 3px solid #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.switch input {
  display: none;
}

/* Style the switch */
.switch label {
  display: inline-block;
  width: 35px;
  height: 20px;
  background-color: #ccc;
  border-radius: 15px; /* Half of the height to make it rounded */
  position: relative;
  cursor: pointer;
}

/* Style the switch handle */
.switch label:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  top: 2.2px;
  left: 2px;
  transition: 0.3s;
}

/* Move the handle based on checkbox state */
.switch input:checked + label:before {
  transform: translateX(16px);
}

/* Optional: Style the checked state */
.switch input:checked + label {
  background-color: #2b8c34;
}

.recharts-tooltip-wrapper {
  z-index: 2000 !important;
}

.remove-scroll::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.remove-scroll::-webkit-scrollbar-track {
  background: #f7f7f7;
}

/* Handle */
.remove-scroll::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
.remove-scroll::-webkit-scrollbar-thumb:hover {
  background: #f7f7f7;
}
